import React from 'react';

import { trackEvent } from '~/utils';
import ErrorLayout from '~/components/layout/ErrorLayout';

const NotFoundError: React.FC = () => {
  const trackPrevEvent = () => {
    trackEvent('error', 'goBackFrom404');
  };

  return (
    <ErrorLayout
      iconUrl='/errorImage/img-error-404.png'
      title='페이지를 찾을 수 없습니다.'
      sub={`요청하신 페이지가 삭제 혹은 변경되었거나,\n일시적으로 사용이 중단되었습니다.`}
      trackPrevEvent={trackPrevEvent}
    />
  );
};

export default NotFoundError;
